<template>
  <v-card id="statsCard">
    <v-card-title class="pb-0">
      <v-icon class="pe-2">
        mdi-chart-line
      </v-icon>
      Last 30 days
    </v-card-title>
    <v-card-text>
      <line-chart
        :chart-options="chartOptions"
        :chart-data="chartData"
        dataset-id-key="label"
        :height="160"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {supabase} from "../../supabase";
import {DateTime, Duration, Interval} from 'luxon';
import {Line as LineChart} from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: "MovementStats",
  props: {
    airfieldId: {type: String, required: true},
  },

  components: {
    LineChart,
  },

  mounted() {
    this.fetchStats();
  },

  watch: {
    airfieldId: function () {
      this.fetchStats();
    },
  },

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            align: "middle",
            labels: {
              boxWidth: 10,
            },
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: undefined,
            },
          },
        },
      },
    };
  },

  methods: {
    async fetchStats() {
      try {
        const data = await this.getStats();

        this.chartData = {
          labels: [],
          datasets: [],
        };

        this.chartData.labels = data.stats.labels.map(function (label) {
          return DateTime.fromISO(label).toFormat("ccc d");
        });

        this.chartData.datasets.push({
          label: 'Arrivals',
          borderColor: '#66BB6A',
          cubicInterpolationMode: "monotone",
          data: data.stats.arrivals,
        });

        this.chartData.datasets.push({
          label: 'Departures',
          borderColor: '#29B6F6',
          cubicInterpolationMode: "monotone",
          data: data.stats.departures,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async getStats() {
      const days = function* (interval) {
        let cursor = interval.start.startOf("day");
        while (cursor < interval.end) {
          yield cursor;
          cursor = cursor.plus({days: 1});
        }
      }

      const numOfDays = 30;
      const now = DateTime.now();
      const from = now
        .minus(Duration.fromObject({days: numOfDays - 1}))
        .set({hour: 0, minute: 0, second: 0, millisecond: 0});
      const to = now.set({hour: 23, minute: 59, second: 59, millisecond: 999});

      const results = {
        stats: {
          labels: [],
          arrivals: [],
          departures: [],
        },
      };

      const interval = Interval.fromDateTimes(from, to);
      for (const day of days(interval)) {
        results.stats.labels.push(day.toISODate());
        results.stats.arrivals.push(0);
        results.stats.departures.push(0);
      }

      const arrivals = await supabase.from("arrival")
        .select("date")
        .eq('airfield_id', this.airfieldId)
        .gte("date", from.toISODate())
        .lte("date", to.toISODate())
        .order("date", {ascending: true});

      arrivals.data.forEach((arrival) => {
        const dateIndex = results.stats.labels.indexOf(arrival.date);
        results.stats.arrivals[dateIndex]++;
      });

      const departures = await supabase.from("departure")
        .select("date")
        .eq('airfield_id', this.airfieldId)
        .gte("date", from.toISODate())
        .lte("date", to.toISODate())
        .order("date", {ascending: true});

      departures.data.forEach((departure) => {
        const dateIndex = results.stats.labels.indexOf(departure.date);
        results.stats.departures[dateIndex]++;
      });

      return results;
    },
  }
};
</script>
