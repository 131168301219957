<template>
  <v-card
    id="widgetInstallCard"
    color="homebuttonbg"
    class="homebuttoncolour--text fill-height"
    dark
  >
    <v-card-title>
      <v-icon 
        class="pe-2"
        color="homebuttoncolour"
      >
        mdi-code-tags
      </v-icon>
      Widgets on Your Website
      <v-spacer />
      <v-btn
        icon
        color="homebuttoncolour"
        elevation="0"
        @click="dismissCard"
        title="Hide this for now, you can choose Widgets in the menu later."
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text 
      class="text-body-1 homebuttoncolour--text"
      color="homebuttoncolour"
    >
      Collect PPR requests and Booking Out on your website with our form
      widgets.
    </v-card-text>
    <v-card-actions class="justify-center pb-4">
      <v-btn
        outlined
        color="homebuttonbg"
        class="homebuttoncolour--text"
        to="/admin/settings/widgets"
      >
        <v-icon left>
          mdi-check
        </v-icon>
        View Widgets
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "WidgetPrompt",

  methods: {
    async dismissCard() {
      await this.$store.dispatch("auth/updateUserHideWidgetInstallPrompt");
    }
  }
};
</script>
