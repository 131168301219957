<template>
  <v-card
    id="landingFeesCard"
    dark
    color="homebuttonbg"
    class="homebuttoncolour--text fill-height"
  >
    <v-card-title
      color="homebuttoncolour"
      class="homebuttoncolour--text"
    >
      <v-icon 
        left
        color="homebuttoncolour"
      >
        mdi-credit-card-check-outline
      </v-icon>
      Accept Landing Fees
      <v-spacer />
      <v-btn
        icon
        color="homebuttoncolour"
        elevation="0"
        @click="dismissCard"
        title="Hide this for now, you can choose in Landing Fees in the menu later."
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text 
      color="homebuttoncolour"
      class="text-body-1 homebuttoncolour--text"
    >
      Card payments make it easy for visiting pilots to pay their landing fees,
      we'll even give them a link to pay in their PPR email. So in a few simple
      steps you can start accepting card payments today.
    </v-card-text>
    <v-card-actions class="justify-center pb-4">
      <v-btn
        outlined
        color="homebuttonbg"
        class="homebuttoncolour--text"
        to="/admin/airfield/setup-payments"
      >
        <v-icon left>
          mdi-check
        </v-icon>
        Sign Up
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "LandingFeesPrompt",

  methods: {
    async dismissCard() {
      await this.$store.dispatch("auth/updateUserHideLandingFeesPrompt");
    }
  }
};
</script>
