<template>
  <div>
    <div>
      <v-row v-if="!hasRequiredOwnerData">
        <v-col
          md="6"
          offset-md="3"
        >
          <v-card>
            <v-card-title>About You</v-card-title>
            <v-card-text class="text-body-1 mb-2">
              Thank you for signing up, let's get started creating your
              airfield.
            </v-card-text>
            <v-card-text>
              <owner-form :on-success="onOwnerSaveSuccess" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="!hasRequiredAirfieldData">
        <v-col
          md="6"
          offset-md="3"
        >
          <v-card>
            <v-card-title>About Your Airfield</v-card-title>
            <v-card-text class="text-body-1 mb-2">
              Now just some basic details about your airfield.
            </v-card-text>
            <v-card-text>
              <airfield-form
                :on-save="onAirfieldSave"
                :on-success="onAirfieldSaveSuccess"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-if="hasRequiredData">
      <v-row>
        <v-col>
          <v-toolbar
            rounded
            elevation="2"
          >
            <v-toolbar-title>
              {{ airfieldName }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              color="primary"
              fab
              x-small
              elevation="0"
              to="/admin/airfield/edit"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col
          v-if="showWidgetInstallPrompt"
          cols="12"
          md="6"
        >
          <widget-prompt />
        </v-col>
        <v-col
          v-if="showLandingFeesPrompt"
          cols="12"
          md="6"
        >
          <landing-fees-prompt />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <movement-stats
            v-if="airfieldId"
            :airfield-id="airfieldId"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card id="arrivalsCard">
            <v-card-title>
              <v-icon class="pe-2">
                mdi-airplane-landing
              </v-icon>
              Today's Arrivals
              <v-spacer />
              <v-btn
                color="primary"
                fab
                x-small
                elevation="0"
                to="/admin/arrivals/new"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <arrivals-list
                :items="todaysArrivals"
                :show-date="false"
                :show-captain="false"
                :show-status="false"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card id="departuresCard">
            <v-card-title>
              <v-icon class="pe-2">
                mdi-airplane-takeoff
              </v-icon>
              Today's Departures
              <v-spacer />
              <v-btn
                color="primary"
                fab
                x-small
                elevation="0"
                to="/admin/departures/new"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <departures-list
                :items="todaysDepartures"
                :show-date="false"
                :show-captain="false"
                :show-status="false"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AirfieldForm from "../../components/AirfieldForm";
import OwnerForm from "../../components/OwnerForm";
import ArrivalsList from "../../components/ArrivalsList.vue";
import DeparturesList from "../../components/DeparturesList.vue";
import WidgetPrompt from "../../components/dashboard/WidgetPrompt.vue";
import LandingFeesPrompt from "../../components/dashboard/LandingFeesPrompt.vue";
import MovementStats from "../../components/dashboard/MovementStats.vue";

export default {
  components: {
    AirfieldForm,
    OwnerForm,
    ArrivalsList,
    DeparturesList,
    WidgetPrompt,
    LandingFeesPrompt,
    MovementStats,
  },

  mounted() {
    this.$store.dispatch("arrivals/subscribe", {forToday: true});
    this.$store.dispatch("departures/subscribe", {forToday: true});
  },
  beforeDestroy() {
    this.$store.dispatch("arrivals/unsubscribe");
    this.$store.dispatch("departures/unsubscribe");
  },

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            align: "middle",
            labels: {
              boxWidth: 10,
            },
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: undefined,
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      hasRequiredData: "auth/hasRequiredData",
      hasRequiredAirfieldData: "auth/hasRequiredAirfieldData",
      hasRequiredOwnerData: "auth/hasRequiredOwnerData",
      showWidgetInstallPrompt: "auth/showWidgetInstallPrompt",
      showLandingFeesPrompt: "auth/showLandingFeesPrompt",
      hasStripeAccount: "auth/airfieldHasStripeAccount",
      todaysArrivals: "arrivals/today",
      todaysDepartures: "departures/today",
      airfieldId: "auth/airfieldId",
      airfieldName: "auth/airfieldName",
    }),
  },

  methods: {
    async onAirfieldSave(data) {
      await this.$store.dispatch("auth/updateAirfield", {
        name: data.name,
        icao_code: data.icaoCode?.toUpperCase() || null,
        website_url: data.websiteUrl || null,
        pilot_briefing_url: data.pilotBriefingUrl || null
      });
    },
    onAirfieldSaveSuccess() {
    },
    onOwnerSaveSuccess() {
    },
  }
};
</script>
