<template>
  <validation-observer ref="observer">
    <v-form
      ref="form"
      :disabled="isSubmitting"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Your Name"
              required
              v-model="ownerData.name"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="justify-center mb-1">
        <v-btn
          color="primary"
          rounded
          type="submit"
          :loading="isSubmitting"
        >
          Next
        </v-btn>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";

export default {
  name: "OwnerForm",
  props: {
    onSuccess: {
      type: Function,
      required: true
    }
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      isSubmitting: false,
      ownerData: {
        name: this.$store.state.auth.userDocument?.name || ""
      }
    };
  },

  methods: {
    onSubmit: function() {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            const ownerData = this.ownerData;
            await this.$store.dispatch("auth/updateUser", {
              name: ownerData.name
            });

            this.onSuccess();
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
